import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import funcs from '@/functions/function'
// import { provide } from 'vue'
// import Home from '../views/Home.vue'
// import cookie from '@/functions/cookie'

/* import LogRocket from 'logrocket'
LogRocket.init('ephrha/signon') */


let validSession = false
let isSU = false

const axios = require('axios').default

async function chkSession() {

    try {
        // console.info('- - Router Session')
        const res = await axios.get('/auth/session' )
        // console.info('- - - ROUTER chkSession', res.data.status, res.data.data.userId, res.data.data.displayName)
        // console.info('- - - ROUTER chkSession data', JSON.stringify(res.data.data))
        // console.info('- - - ROUTER chkSession', JSON.stringify(res.data.data))
        // console.info('- - - ROUTER chkSession jPlan', JSON.stringify(res.data.data.jPlan))
        // console.info('- - - ROUTER chkSession jTeam', JSON.stringify(res.data.data.jTeam))
        // console.info('- - - ROUTER chkSession openId', res.data.data.openId)
        // console.info('S2: ROUTER chkSession RES', res.data.status, '{ sessionId: ', res.data.data.sessionId, 'onlineId:', res.data.data.onlineId, 'wlId: ', res.data.data.wlId, ' entityId:', res.data.data.entityId, ' team:', res.data.data.package, ' userId:', res.data.data.userId, ' displayName:', res.data.data.displayName, '}')

        if( res.data.status === 1) {
            validSession = true
            const sessionId = localStorage.getItem('sessionId')
            // const userId = localStorage.getItem('userId')

            if (sessionId === undefined || sessionId === 'undefined' || sessionId === null || sessionId === 'null') {
                localStorage.setItem('userId', res.data.data.userId)
                localStorage.setItem('sessionId', res.data.data.sessionId)
                localStorage.setItem('displayName', res.data.data.displayName)

            }

            if (localStorage.getItem('avatar') === undefined || localStorage.getItem('avatar') === 'undefined' || 
                localStorage.getItem('avatar') === null || localStorage.getItem('avatar') === 'null' || localStorage.getItem('avatar') === '') {
                if (res.data.data.jAvatar !== undefined) {
                    localStorage.setItem('avatar', res.data.data.jAvatar.imgBase64)
                }
            }

            // localStorage.setItem('userId', res.data.data.userId)
            // localStorage.setItem('displayName', res.data.data.displayName)
            localStorage.setItem('userIsVerified', res.data.data.isVerified) // User verify KYC
            localStorage.setItem('userStatus', res.data.data.status) // user activated his account via email link
            localStorage.setItem('userExpired', res.data.data.dateDelete)
            localStorage.setItem('wlId', res.data.data.wlId)
            localStorage.setItem('entityId', res.data.data.entityId)
            localStorage.setItem('package', res.data.data.plan)
            localStorage.setItem('structure', res.data.data.structure)
            localStorage.setItem('openId', res.data.data.openId)
            localStorage.setItem('team', funcs.encodeJsonToStr(res.data.data.jTeam))
            localStorage.setItem('permission', funcs.encodeJsonToStr(res.data.data.jPermission))
            localStorage.setItem('entity', funcs.encodeJsonToStrSimple(res.data.data.jEntity))  // use to detect isSignOn
            localStorage.setItem('planInfo', funcs.encodeJsonToStrSimple(res.data.data.jPlan))  // my subscribed plan info
            localStorage.setItem('geoInfo', funcs.encodeJsonToStrSimple(res.data.data.jGeoLocation))  // my subscribed plan info
            if (res.data.data.openId === 'GOOGLE') {
                localStorage.setItem('googleToken', funcs.encodeJsonToStrSimple(res.data.data.jGoogleToken))  // if login using google
            }

            if(res.data.data.jTeam && res.data.data.jTeam.length > 0) {
                for (var i = 0; i < res.data.data.jTeam.length; i++) {

                    if( res.data.data.jTeam[i].id === '2' && res.data.data.jEntity.entityId === '1') {
                        isSU = true
                    }
                }
            }

            /* LogRocket.identify(res.data.data.userId, {
              name: res.data.data.displayName,
              email: res.data.data.userId,
            
              // Add your own custom user variables here, ie:
              subscriptionType: 'pro'
            }) */

        } else {
            validSession = false
            isSU = false
            
            if (res && res.data !== null && res.data !== undefined) {
                if (res.data.message !== 'no_session' ) {
                  funcs.addLog('router', 'chkSession', res)
                }
            }
        }

    } catch (error) {
        // console.info('ROUTER chkSession ERROR', error)
        validSession = false
        isSU = false

        if (error.code === 'undefined' || error.code === undefined) {
        } else {
            funcs.addLog('router', 'chkSession - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
        }
        
    }
    
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/auth/Login.vue'),
    meta: { requiresAuth: false }
    // component: Home
  },
  {
    path: '/avatar/:avatarId',
    name: 'Avatar',
    component: () => import('../views/Avatar.vue'),
  },
  {
    path: '/logo/:logoId',
    name: 'Logo',
    component: () => import('../views/Logo.vue'),
  },
  {
    path: '/signature/:signatureId',
    name: 'Signature',
    component: () => import('../views/Signature.vue'),
  },
  {
    path: '/kyc/:kycId',
    name: 'KYC',
    component: () => import('../views/KYC.vue'),
  },
  {
    path: '/kycentity/:kycId',
    name: 'KYCEntity',
    component: () => import('../views/KYCEntity.vue'),
  },
  {
    path: '/p/:projectId',
    name: 'InfoProj',
    component: () => import('../views/sign/Info.vue'),
  },
  {
    path: '/d/:documentId',
    name: 'InfoDoc',
    component: () => import('../views/sign/Info.vue'),
  },
  {
    path: '/p/:projectId/:documentId',
    name: 'ViewProjDoc',
    component: () => import('../views/sign/ProjDoc.vue'),
  },
  {
    path: '/s/:signatureId',
    name: 'VerifySign',
    component: () => import('../views/VerifySign.vue'),
  },
  {
    path: '/terms',
    name: 'TermsConditions',
    component: () => import('../views/sign/TermsConditions.vue'),
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue'),
  },
  {
    path: '/auth/signup',
    name: 'SignUp',
    component: () => import('../views/auth/SignUp.vue'),
  },
  /* {
    path: '/auth/signup/:signType',
    name: 'SignUpTeam',
    component: () => import('../views/auth/SignUp.vue'),
  }, */
  {
    path: '/e/:teamRefCode',
    name: 'SignUpTeam',
    component: () => import('../views/auth/SignUp.vue'),
  },
  {
    path: '/r/:referralCode',
    name: 'SignUpReferral',
    component: () => import('../views/auth/SignUp.vue'),
  },
  {
    path: '/auth/activate/:activationCode',
    name: 'Activate',
    component: () => import('../views/auth/Activate.vue'),
  },
  {
    path: '/auth/forgotpassword',
    name: 'ForgotPassword',
    component: () => import('../views/auth/ForgotPassword.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/auth/resetPassword/:activationCode',
    name: 'ResetPassword',
    component: () => import('../views/auth/ResetPassword.vue'),
    // meta: { requiresAuth: false }
  },
  {
    path: '/auth/preference',
    name: 'Preference',
    component: () => import('../views/auth/Preference.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/preference2',
    name: 'Preference2',
    component: () => import('../views/auth/Preference2.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/preference/payment',
    name: 'PaymentOld',
    component: () => import('../views/auth/PaymentOld.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/auth/Payment.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payment/:result',
    name: 'PaymentResult',
    component: () => import('../views/auth/PaymentResult.vue'),
    meta: { requiresAuth: true }
  },
  /* {
    path: '/payment/failed',
    name: 'PaymentFailed',
    component: () => import('../views/auth/Payment.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payment0',
    name: 'PaymentTest0',
    component: () => import('../views/auth/PaymentTest0.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/preference/payment1',
    name: 'Payment1',
    component: () => import('../views/auth/Payment1.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/preference/payment2',
    name: 'Payment2',
    component: () => import('../views/auth/Payment2.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/preference/payment3',
    name: 'Payment3',
    component: () => import('../views/auth/Payment3.vue'),
    meta: { requiresAuth: true }
  }, */
/*   {
    path: '/auth/preference/bill',
    name: 'PreferenceBill',
    component: () => import('../views/auth/Preference.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/preference/upgrade/:packageId',
    name: 'PreferenceUpgrade',
    component: () => import('../views/auth/Preference.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/preference/upgrade/:packageId/payment',
    name: 'PreferencePayment',
    component: () => import('../views/auth/Preference.vue'),
    meta: { requiresAuth: true }
  }, */
  {
    path: '/auth/referral',
    name: 'Referral',
    component: () => import('../views/auth/Referral.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/report',
    name: 'DashboardReport',
    component: () => import('../views/dashboard/Report.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard/Overview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/project/:projectId',
    name: 'ProjectOverview',
    meta: { requiresAuth: true },
    component: () => import('../views/project/Overview.vue')
  },
  {
    path: '/project',
    name: 'ProjectList',
    component: () => import('../views/project/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/project2',
    name: 'ProjectList2',
    component: () => import('../views/project/List2.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/doc/verify',
    name: 'VerifyDoc',
    component: () => import('../views/document/Verify.vue'),
  },
  {
    path: '/plan',
    name: 'Plan',
    component: () => import('../views/Plan.vue'),
  },
  {
    path: '/pdf/view/:projectId/:documentId/:fr',
    name: 'PdfView',
    component: () => import('../views/pdf/View.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/signatory',
    name: 'Signatory',
    component: () => import('../views/pdf/Detail.vue'),
    meta: { requiresAuth: true }
  },  
  {
    path: '/support',
    name: 'Support',
    component: () => import('../views/Support.vue'),
  },  
  {
    path: '/uploadDoc/:projectId',
    name: 'UploadDoc',
    component: () => import('../views/UploadDoc.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/uploadDoc',
    name: 'UploadDocNew',
    component: () => import('../views/UploadDoc.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  /* Temp test */
  {
    path: '/uploadDoc2/:projectId',
    name: 'UploadDoc2',
    component: () => import('../views/UploadDoc2.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/uploadDoc2',
    name: 'UploadDocNew2',
    component: () => import('../views/UploadDoc2.vue'),
    meta: { requiresAuth: true }
  },
  /* // End Temp test */
  {
    path: '/addSignee',
    name: 'AddSignee',
    component: () => import('../views/AddSignee.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reviewInvite',
    name: 'ReviewInvite',
    component: () => import('../views/ReviewInvite.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/form',
    name: 'FormOverview',
    component: () => import('../views/form/Overview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/apiIntegration',
    name: 'ApiIntegration',
    component: () => import('../views/api/Api.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow',
    name: 'WorkFlow',
    component: () => import('../views/workflow/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/add',
    name: 'AddWorkFlow',
    component: () => import('../views/workflow/Create.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/doc',
    name: 'WorkFlowDoc',
    component: () => import('../views/workflow/Document.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/docconfig',
    name: 'WorkFlowDocConfig',
    component: () => import('../views/workflow/DocConfig.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/obj',
    name: 'WorkFlowObj',
    component: () => import('../views/workflow/Object.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/version/list',
    name: 'WorkFlowVersionList',
    component: () => import('../views/workflow/VersionList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/version/:documentId',
    name: 'WorkFlowVersion',
    component: () => import('../views/workflow/Version.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/config',
    name: 'WorkFlowConfig',
    component: () => import('../views/workflow/Configuration.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/detail/:projectId',
    name: 'WorkflowFolderDet',
    component: () => import('../views/workflow/FolderDetail.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/workflow/initiate/:workflowId',
    name: 'WorkflowInitiate',
    component: () => import('../views/workflow/Initiate.vue'),
    meta: { requiresAuth: true }
  },
  /* {
    path: '/workflow/:object',
    name: 'SignatoryWorkflow',
    component: () => import('../views/pdf/Detail.vue'),
    meta: { requiresAuth: true }
  }, */
  {
    path: '/contact/list',
    name: 'ContactList',
    component: () => import('../views/contact/List.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contact/:userId',
    name: 'ContactEdit',
    component: () => import('../views/contact/Edit.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import('../views/team/Team.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/team/permission/:teamId',
    name: 'TeamPermission',
    component: () => import('../views/team/Permission.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/team/member',
    name: 'Member',
    component: () => import('../views/team/Member.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/team/member/:userId',
    name: 'MemberProfile',
    component: () => import('../views/team/MemberProfile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/backoffice/img/kyc/:kycId/:userId',
    name: 'BOKYC',
    component: () => import('../views/backoffice/KYC.vue'),
  },
  {
    path: '/backoffice/img/kycentity/:kycId/:entityId',
    name: 'BOKYCEntity',
    component: () => import('../views/backoffice/KYCEntity.vue'),
  },
  {
    path: '/backoffice/configuration',
    name: 'BOConfiguration',
    component: () => import('../views/backoffice/configuration/Overview.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/kyc',
    name: 'BOKycList',
    component: () => import('../views/backoffice/kyc/List.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/kyc/report',
    name: 'BOKycReport',
    component: () => import('../views/backoffice/kyc/Report.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    // path: '/backoffice/kyc/:userId',
    path: '/backoffice/kyc/detail/:type/:userId',
    name: 'BOKycProfile',
    component: () => import('../views/backoffice/kyc/Profile.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/ekyc',
    name: 'BOEKycList',
    component: () => import('../views/backoffice/kyc/EntityList.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/ekyc/:entityId',
    name: 'BOEKycProfile',
    component: () => import('../views/backoffice/kyc/EntityProfile.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/subscriber',
    name: 'BOSubscriber',
    component: () => import('../views/backoffice/subscriber/List.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/subscriber/pending',
    name: 'BOPendingSubscriber',
    component: () => import('../views/backoffice/subscriber/PendingList.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/subscriber/report',
    name: 'BOSubscriberReport',
    component: () => import('../views/backoffice/subscriber/Report.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/subscriber/:userId',
    name: 'BOSubscriberProfile',
    component: () => import('../views/backoffice/subscriber/Profile.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/report',
    name: 'BOReport',
    component: () => import('../views/backoffice/report/Overview.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/report2',
    name: 'BOReport2',
    component: () => import('../views/backoffice/report/Overview2.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/txn',
    name: 'TxnList',
    component: () => import('../views/backoffice/txn/List.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/txnchart',
    name: 'TxnChart',
    component: () => import('../views/backoffice/txn/Chart.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/backoffice/errlog',
    name: 'ErrorLog',
    component: () => import('../views/backoffice/log/ErrorLog.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue'),
    meta: { requiresAuth: true, requiresSu: true }
  },
/*   {
    path: '/api/',
    name: 'ApiMain',
    component: () => import('../views/api/Api.vue'),
    // meta: { requiresAuth: true, requiresSu: true }
  },
  {
    path: '/api/:path',
    name: 'Api',
    component: () => import('../views/api/Api.vue'),
    // meta: { requiresAuth: true, requiresSu: true }
  }, */
/*   {
    path: '/pageNotFound',
    name: 'PageNotFound',
    component: () => import('../views/Error404.vue')
  }, */
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('../views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  if (to.name === 'ForgotPassword') {

  } else {
      await chkSession() // ensure if user login -  session not expired

  }

  // const userId = localStorage.getItem('userId')
  const sessionId = localStorage.getItem('sessionId')

  let isLogin = true
  
  // Detect if login based on sessionId instead of userId for auto fill login username
  if (sessionId === undefined || sessionId === null || sessionId === 'null') {
    isLogin = false
  }

  // console.info('*** *** *** ROUTER b4', 'requiresAuth:', to.meta.requiresAuth, 'isLogin', isLogin, 'validSession', validSession, 'isSU', isSU, ' | sessionId: ', sessionId)

  // Cannot use store here
  // console.info('ROUTER from Path', from.fullPath) 
  // console.info('ROUTER from Name', from.name) 
  // console.info('ROUTER to', to.path) 
  // console.info('ROUTER to', to) 
  // console.info('ROUTER to.name', to.name) 
  // console.info('ROUTER to.meta.requiresAuth', to.meta.requiresAuth) 
  // console.info('ROUTER to.fullPath', to.fullPath)

  if (from.path === '/signatory') {
    localStorage.removeItem('hasClickSignatoryToolbox') // Handle evertime access signatory show leftDrawer by default
  }

  if (from.path.substring(0, 10) === '/pdf/view/') {
    localStorage.removeItem('hasClickViewToolbox')  // Handle evertime access preview doc show leftDrawer by default
  }
  
  if (to.meta.requiresAuth === true) {

      if (isLogin === true && validSession === true) {

          if (to.meta.requiresSu === true) {
              if (isSU === true) {
                  next()
              } else {
                next({ path: '/pageNotFound' })
              }
          } else {
              next()
          }

      } else if ( to.name === 'Login' && to.query.m === '1') {
        // Manual logout
        next({ path: '/auth/login' })

      } else {
          if (to.meta.requiresAuth === true && isLogin === false && validSession === false) {
            console.info('---- timeout - opt 1', 'isLogin: ', isLogin, typeof isLogin,  'validSession: ', validSession, typeof validSession)
            next({ path: '/auth/login/'})
          }

          // System timeout
          if (to.meta.requiresAuth === true && validSession === false) {
            console.info('---- timeout - opt 2', 'isLogin:', isLogin, typeof isLogin, 'validSession:', validSession, typeof validSession)
            next({ path: '/auth/login/', query: {e: 'ns'} })
          }

          if (to.name === 'InfoProj') {
              next({ path: '/auth/login/', query: { redirect: to.name, p: to.params.projectId, e: 'timeout' } })

          } else if ( to.name === 'ViewProjDoc') {
              next({ path: '/auth/login/', query: { redirect: to.name, p: to.params.projectId, d: to.params.documentId, e: 'timeout' } })

          } else if ( to.name === 'ViewDocInfo') {
              next({ path: '/auth/login/', query: { redirect: to.name, d: to.params.documentId, e: 'timeout' } })

          } else if ( to.name === 'UploadDocNew') {
              next({ path: '/auth/login', query: { redirect: 'UploadDocNew', e: 'timeout' } })

          } else if ( to.name === 'UploadDoc') {
            next({ path: '/auth/login', query: { redirect: 'UploadDoc', p: to.params.projectId } })

          } else if ( to.name === 'ProjectOverview') {
              next({ path: '/auth/login', query: { redirect: 'ProjectOverview', p: to.params.projectId } })

          } else if ( to.name === 'ProjectList') {
              next({ path: '/auth/login', query: { redirect: 'ProjectList', e: 'timeout' } })

          } else if ( to.name === 'VerifyDoc') {
              next({ path: '/auth/login', query: { redirect: 'VerifyDoc', e: 'timeout' } })

          } else if ( to.name === 'PdfView') {
            next({ path: '/auth/login', query: { redirect: 'PdfView', p: to.params.projectId, d: to.params.documentId, e: 'timeout' } })

          } else if ( to.name === 'Signatory') {
            next({ path: '/auth/login', query: { redirect: 'Signatory', e: 'timeout' } })

          } else if ( to.name === 'PdfDetail') {
            next({ path: '/auth/login', query: { redirect: 'PdfDetail', e: 'timeout' } })

          } else if ( to.name === 'AddSignee') {
            next({ path: '/auth/login', query: { redirect: 'AddSignee', e: 'timeout' } })

          } else if ( to.name === 'ReviewInvite') {
            next({ path: '/auth/login', query: { redirect: 'ReviewInvite', e: 'timeout' } })

          } else if ( to.name === 'ManageAccount') {
            next({ path: '/auth/login', query: { redirect: 'ManageAccount', e: 'timeout' } })

          }

      }
      
  } else if ( to.meta.requiresAuth === false) {
      // console.info('---- X req LOGIN', to.path)
      // Permission denied - E.g. after login cannot accesss login page
      if (isLogin && validSession) {
          
        if (to.path === '/') {
          next({ path: '/dashboard' })

        } else {
            next({ path: '/pageNotFound' })
        }

      } else {
          next()
      }

  }
  else
  {
      // console.info('*** everyone can access')
      next()
  }

})

export default router

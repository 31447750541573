// import axios2 from 'axios'
import axios from '@/functions/axios-instance'

export default {
    name: "function",
    convDateTimeFormat (d="", format="") {
        // input format: 2021-11-18T23:11:41.000Z (from backend) OR Fri Nov 19 2021 15:11:41 GMT+0800 (Malaysia Time)
        const date = new Date(d)

        if (format === "date") {
            const month = (date.getMonth() * 1 + 1) + ''
            const day = date.getDate() + ''

            return date.getFullYear() + '-' + (month.length === 2 ? month : '0' + month) + '-' + (day.length === 2 ? day : '0' + day)

        } else if (format === "text") {

            // const month = (date.getMonth() * 1 + 1) + ''
            const day = date.getDate() + ''
            const hour = date.getHours() + ''
            const minute = date.getMinutes() + ''
            const month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

            return (day.length === 2 ? day : '0' + day) + month[date.getMonth()] + date.getFullYear()  + ' ' + (hour.length === 2 ? hour : '0' + hour) + ':' + (minute.length === 2 ? minute : '0' + minute)
        
        } else if (format === "datetext") {

            // const month = (date.getMonth() * 1 + 1) + ''
            const day = date.getDate() + ''
            const hour = date.getHours() + ''
            const minute = date.getMinutes() + ''
            const month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

            return (day.length === 2 ? day : '0' + day) + month[date.getMonth()] + date.getFullYear()

        } else if (format === "datetime") {
            // convert to YYYY-MM-DD HH:II
            const month = (date.getMonth() * 1 + 1) + ''
            const day = date.getDate() + ''
            const hour = date.getHours() + ''
            const minute = date.getMinutes() + ''

            return date.getFullYear() + '-' + (month.length === 2 ? month : '0' + month) + '-' + (day.length === 2 ? day : '0' + day) + ' ' + (hour.length === 2 ? hour : '0' + hour) + ':' + (minute.length === 2 ? minute : '0' + minute)

        } else if (format === "fulldatetime") {
            // convert to YYYY-MM-DD HH:II:SS
            const month = (date.getMonth() * 1 + 1) + ''
            const day = date.getDate() + ''
            const hour = date.getHours() + ''
            const minute = date.getMinutes() + ''
            const second = date.getSeconds() + ''

            return date.getFullYear() + '-' + (month.length === 2 ? month : '0' + month) + '-' + (day.length === 2 ? day : '0' + day) + ' ' + (hour.length === 2 ? hour : '0' + hour) + ':' + (minute.length === 2 ? minute : '0' + minute) + ':' + (second.length === 2 ? second : '0' + second)
        
        } else if (format === "datetimegmt") {
            // convert to 15Nov2024 06:00PM GMT+8
            const month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
            const day = date.getDate() + ''
            // const hour = date.getHours() + ''
            const minute = date.getMinutes() + ''
            // const second = date.getSeconds() + ''

            const ampm = date.getHours() >= 12 ? 'PM' : 'AM'
            let hour2 = (date.getHours() % 12) + '' // Convert 24-hour format to 12-hour format

            const offsetMinutes = date.getTimezoneOffset()
            const offsetHours = -offsetMinutes / 60 // The offset is in minutes, and we want it in hours.

            // return (day.length === 2 ? day : '0' + day) + month[date.getMonth()] + date.getFullYear() + ' ' + (hour.length === 2 ? hour : '0' + hour) + ':' + (minute.length === 2 ? minute : '0' + minute) + ':' + (second.length === 2 ? second : '0' + second) + ' GMT' + (offsetHours >= 0 ? '+' : '') + offsetHours
            return (day.length === 2 ? day : '0' + day) + month[date.getMonth()] + date.getFullYear() + ' ' + (hour2.length === 2 ? hour2 : '0' + hour2) + ':' + (minute.length === 2 ? minute : '0' + minute) + ampm + ' GMT' + (offsetHours >= 0 ? '+' : '') + offsetHours
        
        } else if (format === "datetimegmt2") {
            // convert to 15Nov2024 06:00PM GMT+8
            const month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
            const day = date.getDate() + ''
            const hour = date.getHours() + ''
            const minute = date.getMinutes() + ''
            // const second = date.getSeconds() + ''

            const ampm = date.getHours() >= 12 ? 'PM' : 'AM'
            let hour2 = (date.getHours() % 12) + '' // Convert 24-hour format to 12-hour format

            const offsetMinutes = date.getTimezoneOffset()
            const offsetHours = -offsetMinutes / 60 // The offset is in minutes, and we want it in hours.

            return (day.length === 2 ? day : '0' + day) + month[date.getMonth()] + date.getFullYear() + ' ' + date.getHours() + ':' + (minute.length === 2 ? minute : '0' + minute) + ' ' + (offsetHours >= 0 ? '+' : '') + (offsetHours.toString.length === 2 ? offsetHours : '0' + offsetHours) + ':00'
        
        } else {
            // Coverted to FORMAT: Fri Nov 19 2021 15:11:41 GMT+0800 (Malaysia Time)
            return date.toDateString() + ' ' + date.toTimeString()
        }
    },
    getDateTimeDiff (dateFrom="", dateTo="") {
        // input Format: Fri Nov 19 2021 15:11:41 GMT+0800 (Malaysia Time)
    
        const d1 = new Date(dateFrom)
        const d2 = new Date(dateTo)

        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24
        const diffInTime = d2.getTime() - d1.getTime()
        const diffInDay = Math.floor(diffInTime / oneDay)
        const diffInHour = Math.floor(diffInTime / (1000 * 60 * 60)) // in hour
        const diffInMinute = Math.floor(diffInTime / (1000 * 60)) // in minute

        if (diffInDay >= 365) {
            const year = Math.floor(diffInDay / 365)
            return year + ' ' +( year === 1 ? 'year' : 'years') + ' ago'

        } else if (diffInDay >= 30) {
            const month = Math.floor(diffInDay / 30)
            return month + ' ' +( month === 1 ? 'month' : 'months') + ' ago'

        } else if (diffInDay >= 1) {
            return diffInDay + ' ' + ( diffInDay === 1 ? 'day' : 'days') + ' ago'

        } else if (diffInHour >= 1) {
            return diffInHour + ' ' + ( diffInHour === 1 ? 'hour' : 'hours') + ' ago'

        } else if(diffInMinute >= 1) {
            return diffInMinute + ' ' + ( diffInMinute === 1 ? 'minute' : 'minutes') + ' ago'

        } else {
            const diffInSecond = Math.floor(diffInTime / 1000)

            if (diffInSecond > 0) {
                return diffInSecond + ' ' + ( diffInSecond === 1 ? 'second' : 'seconds') + ' ago'
            } else {
                return 'Just now'
            }
        }
    },
    getDateTimeDiffInDay (dateFrom="", dateTo="") {
        // input Format: Fri Nov 19 2021 15:11:41 GMT+0800 (Malaysia Time)
    
        const d1 = new Date(dateFrom)
        const d2 = new Date(dateTo)

        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24
        const diffInTime = d2.getTime() - d1.getTime()
        const diffInDay = Math.round(diffInTime / oneDay)

        return diffInDay
    },
    getDateTimeDiffSimple (dateFrom="", dateTo="") {
        // input Format: Fri Nov 19 2021 15:11:41 GMT+0800 (Malaysia Time)
    
        const d1 = new Date(dateFrom)
        const d2 = new Date(dateTo)

        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24
        const diffInTime = d2.getTime() - d1.getTime()
        const diffInDay = Math.round(diffInTime / oneDay)
        const diffInHour = Math.round(diffInTime / (1000 * 60 * 60)) // in hour
        const diffInMinute = Math.round(diffInTime / (1000 * 60)) // in minute

        if (diffInDay >= 365) {
            const year = Math.ceil(diffInDay / 365)
            return year + ' ' +( year === 1 ? 'year' : 'years')

        } else if (diffInDay >= 30) {
            const month = Math.ceil(diffInDay / 30)
            return month + ' ' +( month === 1 ? 'month' : 'months')

        } else if (diffInDay >= 1) {
            return diffInDay + ' ' + ( diffInDay === 1 ? 'day' : 'days')

        } else if (diffInHour >= 1) {
            return diffInHour + ' ' + ( diffInHour === 1 ? 'hour' : 'hours')

        } else if(diffInMinute >= 1) {
            return diffInMinute + ' ' + ( diffInMinute === 1 ? 'minute' : 'minutes')

        } else {
            const diffInSecond = Math.round(diffInTime / 1000)
            if (diffInSecond > 0) {
                return diffInSecond + ' ' + ( diffInSecond === 1 ? 'second' : 'seconds')
            } else {
                return 'Just now'
            }
        }
    },
    convSize (size=0) {
        const mb = size / (1024 * 1024)
        const kb = size / 1024

        if (mb >= 0.1) {
            return mb.toFixed(1) + 'MB'
        } else if(kb > 0) {
            return kb.toFixed(1) + 'KB'
        } else {
            return size + 'B'
        }

    },
    convCamelString (text="") {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    },
    convSpaceEvery4Chars (text="") {
        return text.replace(/(.{4})(?=.)/g, '$1 ')
    }, 
    maskText(text="", format="") {
        if (format === 'half') {
            const len = text.length
            const temp = text.substring( 1, len-1)
            return text.substring(0, 1) + temp.replaceAll(/[0-9a-zA-Z,.@']/gi, '*') + text.charAt(len - 1)

        } else {
            return text.replaceAll(/[0-9a-zA-Z,.@']/gi, '*')
        }

    },
    encodeJsonToStr(text="") {
        // Sample input: [{}, {}]
        let newText = JSON.stringify(text)
        newText = btoa(encodeURIComponent(newText))

        return newText
    },
    decodeStrToJson(text="") {
        // Sample output: [{}, {}]
        let newText = text
        newText = decodeURIComponent(window.atob(text))
        newText = eval(newText)

        return newText
    },
    encodeJsonToStrSimple(text="") {
        // Sample input: {}

        let tmpObj = []
        tmpObj.push(text)

        let newText = JSON.stringify(tmpObj)
        newText = btoa(encodeURIComponent(newText))

        return newText
    },
    decodeStrToJsonSimple(text="") {
        // Sample output: {}

        let newText = decodeURIComponent(window.atob(text))
        newText = eval(newText)

        return newText[0]
    },
    isEmptyObject(obj={}) {
        if (obj === null || obj === undefined || obj === '') {
            return true

        } else {

            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    return false
                }
            }
            return true
        }
    },
    getError(res = { "status": 0, "message": "", "description": "" }) {
        // Sample: { "status": 1001, "message": "no_session", "description": "session id is undefined" }
        if (res.message !== null && res.message !== undefined) {
            if (res.message === 'user_not_found') {
                return 'Sorry, you are either not registered or you have forgotted your account. Please register for a new account. (' + res.status + ').'
    
            } else if (res.message === 'no_privilege') {
                return 'Sorry, you do not have permission to access this page. (' + res.status + ').'
    
            } else if (res.message === 'invalid_open_id') {
                return 'Username is registered with Single Sign-On (SSO). Please login via Single Sign-On e.g. Google. (' + res.status + ').'
    
            } else if (res.message === 'invalid_referral_id') {
                return 'Invalid reference code.'
    
            } else if (res.message === 'user_not_found') {
                return 'User is not exists. (' + res.status + ').'
    
            } else if (res.message === 'action_not_match') {
                return 'You might have done something unusual. Please report the error (error code) to our support if you continue to face the same problem. (' + res.status + ').'
    
            } else if (res.message === 'deleting_user') {
                return 'We found that your account is expired. Sorry to inform that we are clearing your account, kindly register a new account again after a minute later.'
            
            } else if (res.message === 'no_permission' && res.status === 30016) {
                return 'Sorry, you do not have permission to perform the action.'
            
            } else if (res.message === 'file_protected') {
                return 'File is protected and cannot be uploaded.'
                
            } else if (res.status === undefined ) {
                console.info('network errmsg desc', res.message, res.description)
                console.info('network err', res)
                return 'Network error. Please try again later.'
    
            } else {
                // console.info('code', res.data.status, typeof res.data.status)
                // return error.message + '. ' + res.data.status + ': ' + res.data.message
                // temporary show message to ease debug
                return 'Something unsual. Please report the error (error code) to our support at wecare@signon.my. (' + res.status + ':' + res.message + ').'
            }
        }

        return ''
    },
    clearPrevLocalStorage() {
        // console.info('- - - clearPrevLocalStorage')
        
        // ProjDoc
        localStorage.removeItem('leftSignDrawer')
        localStorage.removeItem('leftViewDrawer')

        localStorage.removeItem('project')
        localStorage.removeItem('signatoryCtrl')
        localStorage.removeItem('signatoryDoc')
        localStorage.removeItem('signatorySignee')
    },
    addLog(mod="", func="", err="") {
        const p = {
            userId: localStorage.getItem('userId'),
            category: mod,
            title: func,
            description: err
        }

        axios.post('/report/log', p)
            .then((res) => {
                // console.info('addLog success', JSON.stringify(res))
            })
            .catch((error) => {
                if (error.code !== undefined) {
                    console.info('addLog | ERR', error, error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                }
            })
    }

}